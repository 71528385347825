<template>
  <div style="position: relative; height: 300px; overflow: hidden;">
    <b-overlay 
      :show="loading"
      spinner-variant="primary"
      spinner-small 
      rounded="sm">
      <highcharts :options="chartOptions"></highcharts>
      <div v-if="showYearsLabel"
        class="row text-center h6 p-0 text-300 font-weight-bold"
        style="position: relative; left: 1.8rem; top: -3.5rem; width: 740px;">
        <div class="col-4 font-proxima px-0">
          {{activeBPlan.startYear}}
        </div>
        <div class="col-4 font-proxima px-0">
          {{activeBPlan.startYear + 1}}
        </div>
        <div class="col-4 font-proxima px-0">
          {{activeBPlan.startYear + 2}}
        </div>
      </div>
    </b-overlay>
    
  </div>
</template>

<script>

export default {
  props: ['chartId'],
  data: () => ({
    chartOptions: { 
      chart: {
        type: 'column',
        width: 720,
        height: 350,
        backgroundColor: null
      },
      legend: false,
      credits: false,
      title: {
          text: null
      },
      xAxis: {
        categories: []
      }
    },
    loading: false,
    showYearsLabel: false
  }),
  async created() {
    this.loading = true;
    this.chartOptions = await this.$store.dispatch(`${this.chartId}Data`, {width: 740, height: 300});
    this.showYearsLabel = this.chartOptions.xAxis.categories.length > 12 ? true : false;
    this.loading = false;
  },

  computed: {
    activeBPlan() {
      return this.$store.getters.getActiveBPlan;
    },
  }
}
</script>

<style lang="scss" scoped>

</style>

