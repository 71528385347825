<template>
  <div class="table-responsive mb-2">
    <table class="table table-sm mb-0 table-dashboard fs--0" style="font-family: 'Proxima Nova'">
      <thead class="bg-400 text-800">
        <tr>                   
          <th 
            class="px-1 text-center" 
            style="min-width: 300px; line-height: 2rem;  vertical-align: middle;" 
            colspan="2">
            Наименование этапа
          </th>
          <th 
            class="px-1 text-center" 
            style="min-width: 80px; vertical-align: middle;">
            Дедлайн
          </th>
          <th 
            class="px-1 text-right" 
            style="min-width: 50px; vertical-align: middle;">
            Ответственный
          </th>
          <th 
            class="px-1 text-center" 
            style="min-width: 150px; vertical-align: middle;">
            Примечание
          </th>
        </tr>
        
      </thead>
      <tbody style="font-size: 0.9rem; line-height: 2rem;">
        <tr v-for="(milestone, index) in milestonesList" :key="index"
          class="hover-shadow" >
          <td class="bg-100 text-center p-1">
              <input 
                class="form-check-input p-0 m-1" 
                :checked="milestone.finished"
                type="checkbox"
                disabled>
          </td>
          <td class="p-0 pl-3 bg-100">
            {{milestone.title}}                
          </td>
          <td class="text-center py-0 px-0 fs--1 bg-100">
            {{milestone.deadline}} 
          </td>
          <td class="text-right py-0 pr-1 bg-100">
            {{milestone.person}}  
          </td>
          <td class="text-center py-0 px-1 bg-100 fs--2"
            style="line-height: 1rem;">
            {{milestone.desc}}  
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="false"
      class="text-right">
      <div class="btn btn-outline-secondary btn-sm mt-3">
        Добавить этап
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  computed: {
    milestonesList() {
      return this.$store.getters.getMilestonesList;
    }
  },
}
</script>

<style>

</style>