var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.selectedOutlineChapter ? _vm.selectedOutlineChapter.length == 0 : true)?_c('div',{staticClass:"row mt-3 w-100 d-flex justify-content-center align-items-center vh-50"},[_vm._m(0),_c('h5',[_vm._v("Загрузка ...")])]):_vm._e(),_c('div',{staticClass:"row mt-3 w-100"},[(_vm.selectedOutlineChapter.length != 0)?_c('div',{staticClass:"col-12 col-md-12 pl-5",staticStyle:{"max-width":"800px"}},[_c('h2',{staticClass:"font-proxima font-weight-bold text-primary"},[_vm._v(" "+_vm._s(_vm.selectedOutlineChapter.title)+" ")]),_vm._l((_vm.selectedOutlineChapter.sections),function(section){return _c('div',{key:section.id,staticClass:"mt-2 mb-3"},[_c('h3',{staticClass:"font-proxima text-secondary my-3 text-800"},[_vm._v(" "+_vm._s(section.title)+" ")]),_vm._l((section.topics),function(topic){return _c('div',{key:topic.id,staticClass:"mt-2 topic"},[_c('div',{staticClass:"d-flex align-items-center topic-head"},[_c('h5',{staticClass:"font-proxima mb-0",class:{
                'd-none text-300': topic.showTitle == false,
                'text-600 mt-2': topic.showTitle == true || !topic.showTitle
              }},[_vm._v(" "+_vm._s(topic.title)+" ")]),(topic.type == 'textTopic')?_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal.topicItemEdit",modifiers:{"topicItemEdit":true}}],staticClass:"btn btn-outline-secondary btn-sm ml-auto edit-btn",on:{"click":function($event){return _vm.setTopicToEdit(topic)}}},[_c('i',{staticClass:"far fa-edit"}),_vm._v(" Изменить ")]):_vm._e()]),(topic.body)?_c('div',{staticClass:"d-flex mt-3"},[_c('p',{staticClass:"font-proxima",domProps:{"innerHTML":_vm._s(topic.body)}})]):(topic.type == 'textTopic')?_c('div',[_vm._m(1,true)]):_vm._e(),(topic.type == 'table')?_c('div',{staticClass:"d-flex mt-3"},[_c(topic.component,{tag:"component"})],1):_vm._e(),(topic.type == 'chart')?_c('div',{staticClass:"d-flex mt-2 pb-3"},[_c('div',{staticStyle:{"height":"300px","z-index":"2","background":"rgba(30, 94, 152, 0.1)","border-radius":"3px"}},[_c('outline-chart-renderer',{attrs:{"chartId":topic.chartId}})],1)]):_vm._e(),_c('div',{staticClass:"d-flex justify-content-between",class:{'mt-3': topic.body == ''}},[_vm._m(2,true),_vm._m(3,true)])])})],2)})],2):_vm._e()]),_c('topic-edit-modal',{attrs:{"topic":_vm.topicToEdit}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner-border mr-3",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mt-2 fs--1 text-300 pl-4"},[_c('i',[_vm._v("Абзац еще не начат, самое время приступить!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"comments"},[_c('i',{staticClass:"far fa-comments mr-2"}),_c('small',[_vm._v("Комментарии")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-300 edited-stamp"},[_c('small',[_c('i',{staticClass:"far fa-edit"}),_vm._v(" среда, 23.03.2020 ")])])
}]

export { render, staticRenderFns }