<template>
  <b-row class="ml-0 w-100">
    <div class="card mb-2 w-100"> 

        <div class="table-responsive">
         <table class="table table-sm mb-0 table-dashboard fs--0" style="font-family: 'Proxima Nova'">
          <thead class="bg-400 text-800">
            <tr>                   
              <th 
                class="pl-3" 
                style="min-width: 300px; vertical-align: middle;" 
                rowspan="2">
                Наименование статьи
              </th>
              <th v-for="y in 3" :key="y"
                class="text-center"
                style="min-width: 30px; border-left: 1px solid #06132550;"
                scope="colgroup">
                {{2019 + y}} <small>год</small>
              </th>
            </tr>
          </thead>
          <tbody id="oneYearSales" style="font-size: 0.7rem;">
            <tr 
              v-for="item in PLData"
              :key="item.key"
              class="hover-shadow">
              <td class="p-0"
                :class="{
                  'pl-3 fs--1': item.type == 'categoryItem',
                  'pl-3 item-total': item.type == 'categoryItemTotal',
                  'sub-item pl-4': item.type == 'categorySubItem',
                  'fs-total': item.type == 'categoryTotal',
                  'fs-total result': item.type == 'categoryTotalResult',
                  'sub-category-total-result text-right fs--2 pr-2': item.type == 'subCategoryTotalResult',
                  'border-top-400': item.type == 'categoryTotal' || item.type == 'categoryTotalResult'
                }">
                {{item.title}}                
              </td>
              <td 
                v-for="(value, index) in item.values"
                :key="index.key"
                class="text-right p-0 pr-1 border-left-300"
                style="vertical-align: bottom; min-width: 90px;"
                :class="{
                  'bg-100': (value == null) && (item.type == 'categoryItem'),                  
                  'item-total': item.type == 'categoryItemTotal',
                  'sub-item': item.type == 'categorySubItem',
                  'border-top-400': item.type == 'categoryTotal',
                  'item-total result': item.type == 'categoryTotalResult',
                  'categoryTotal': item.type == 'categoryTotal' || item.type == 'categoryTotalResult',
                  'categoryTotal negative-value': (item.type == 'categoryTotalResult') && (value < 0),
                  'sub-category-total-result': item.type == 'subCategoryTotalResult',
                  'negative-value': item.type == 'subCategoryTotalResult' && value < 0
                }">
                {{item.type != 'subCategoryTotalResult' ? numFormat(value) : numFormat(value, '0,0.0')}}
                <small v-if="item.type == 'subCategoryTotalResult' && value != 0">%</small>  
              </td>
            </tr>
          </tbody>
          <tfoot class="bg-300 text-800 font-weight-medium"
            style="border-top: 2px double #757e8c;">
            <tr v-for="(item) in PLData.netProfit" :key="item.key">
              <td class="p-0 align-middle"
                :class="{
                  'font-weight-semi-bold p-1': item.type == 'categoryTotalResult',
                  'text-right pr-2 fs--2': item.type == 'subCategoryTotalResult',
                  'sub-category-total-result': item.type == 'subCategoryTotalResult',
                }">
                {{item.title}}
              </td>
              <td 
                v-for="(value, index) in item.values"
                :key="index.key"
                class="text-right p-0 pr-1 fs--2 align-bottom border-left-300 font-weight-bold fs--1"
                :class="{
                  'font-weight-semi-bold p-1': item.type == 'categoryTotalResult',
                  'negative-value': value < 0,
                  'sub-category-total-result': item.type == 'subCategoryTotalResult',
                }">
                {{item.type != 'subCategoryTotalResult' ? numFormat(value) : numFormat(value, '0,0.0')}}
                <small v-if="item.type == 'subCategoryTotalResult' && value != 0">%</small>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </b-row>
</template>

<script>
import numeral from 'numeral'

export default {
  data: () => ({
    activeYear: 0
  }),
  mounted() {
    this.$store.dispatch('calculateTablePL', {activeYear: -1});
  },
  computed: {
    PLData() {
      var tableData = this.$store.getters.getTablePL;
      return tableData
    },

  },

  methods: {
    numFormat(value, dec = '0,0') {
      if (value == 0 || value == null) {
        return '-  '
      }

      return numeral(value).format(dec)
    },
  }

}
</script>

<style lang="scss" scoped>

.border-left-400 {
  border-left: 1px solid #4f5c70 !important;
}

.fs-total {
  height: 1.5rem;
  padding-left: 5px !important;
  background: #34405055;
  font-weight: 600;
  font-size: 0.8rem;
  vertical-align: middle;
  &.result {
    background: #344050;
  }
}

.categoryTotal {
  background: #34405085;
  font-weight: 600;
  vertical-align: bottom;
  font-size: 0.75rem;
}

.sub-category-total-result {
  background: #344050;
  border-top: 1px solid #2a3441;
}

.item-total {
  background: #34405055;
  &.result {
    background: #344050;
  }
}

.sub-item {
  background: #34405050
}

.negative-value {
  color: #3b0b09;
}


</style>