var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-sm mb-0 table-dashboard fs--0",staticStyle:{"font-family":"'Proxima Nova'"}},[_c('thead',{staticClass:"bg-400 text-800"},[_c('tr',[_c('th',{staticClass:"pl-3",staticStyle:{"min-width":"300px","vertical-align":"middle"},attrs:{"rowspan":"2"}},[_vm._v(" Наименование статьи ")]),_vm._l((3),function(y){return _c('th',{key:y,staticClass:"text-center",staticStyle:{"min-width":"80px","border-left":"1px solid #06132550"},attrs:{"scope":"colgroup"}},[_vm._v(" "+_vm._s(_vm.activeBPlan.startYear + (y - 1))+" "),_c('small',[_vm._v("год")])])})],2)]),_c('tbody',{staticStyle:{"font-size":"0.7rem"},attrs:{"id":"oneYearSales"}},_vm._l((_vm.PLData.data),function(item){return _c('tr',{key:item.key,staticClass:"hover-shadow"},[_c('td',{staticClass:"p-0",class:{
                'pl-3 fs--1': item.type == 'categoryItem',
                'pl-3 item-total': item.type == 'categoryItemTotal',
                'sub-item pl-4': item.type == 'categorySubItem',
                'fs-total': item.type == 'categoryTotal',
                'sub-category-total fs-total text-right pr-3': item.type == 'subCategoryTotal',
                'fs-total result': item.type == 'categoryTotalResult',
                'sub-category-total-result text-right fs--2 pr-2': item.type == 'subCategoryTotalResult',
                'border-top-400': item.type == 'categoryTotal' || item.type == 'categoryTotalResult',
                'segment-total border-top-400 title': item.type == 'segmentTotal'
              }},[_vm._v(" "+_vm._s(item.title)+" ")]),_vm._l((item.values),function(value,index){return _c('td',{key:index.key,staticClass:"text-right py-0 pr-1",class:{
                'border-left-300': index != 0,
                'border-left-400': index == 0,
                'item-total': item.type == 'categoryItemTotal',
                'sub-item': item.type == 'categorySubItem',
                'border-top-400': item.type == 'categoryTotal',
                'sub-category-total': item.type == 'subCategoryTotal',
                'item-total result': item.type == 'categoryTotalResult',
                'border-left-400' :   index == 0  || 
                          index == _vm.activeYear + 4 || 
                          index == _vm.activeYear + 5 || 
                          index == _vm.activeYear + 6 || 
                          index == _vm.activeYear + 7,
                'categoryTotal': item.type == 'categoryTotal' || item.type == 'categoryTotalResult',
                'segment-total border-top-400 negative-value': (item.type == 'segmentTotal') && (value < 0),
                'sub-category-total-result': item.type == 'subCategoryTotalResult',
                'segment-total border-top-400': item.type == 'segmentTotal',
                'negative-value': item.type == 'subCategoryTotalResult' && value < 0
              },staticStyle:{"vertical-align":"bottom","min-width":"70px"}},[_vm._v(" "+_vm._s(item.type != 'subCategoryTotalResult' ? _vm.numFormat(value) : _vm.numFormat(value, '0,0.0'))+" "),(item.type == 'subCategoryTotalResult' && value != 0)?_c('small',[_vm._v("%")]):_vm._e()])})],2)}),0),_c('tfoot',{staticClass:"bg-300 text-800 font-weight-medium",staticStyle:{"border-top":"2px double #757e8c"}},_vm._l((_vm.PLData.balance),function(item,rowIndex){return _c('tr',{key:item.key},[_c('td',{staticClass:"p-0 align-middle",class:{
                'font-weight-semi-bold p-1': item.type == 'categoryTotalResult',
                'font-weight-semi-bold text-right pr-2 fs--2': item.type == 'subCategoryTotalResult',
                'font-weight-semi-bold sub-category-total-result': item.type == 'subCategoryTotalResult',
              }},[_vm._v(" "+_vm._s(item.title)+" ")]),_vm._l((item.values),function(value,index){return _c('td',{key:index.key,staticClass:"text-right p-0 pr-1 fs--2 align-bottom",class:{
                'font-weight-semi-bold p-1': item.type == 'categoryTotalResult',
                'negative-value balance': value < 0,
                'fs--2': rowIndex == 1,
                'font-weight-semi-bold fs--1': rowIndex != 1,
                'border-left-300': index != 0,
                'sub-category-total-result': item.type == 'subCategoryTotalResult',
                'border-left-400' : index == 0 || 
                                    index == _vm.activeYear + 4 || 
                                    index == _vm.activeYear + 5 || 
                                    index == _vm.activeYear + 6 || 
                                    index == _vm.activeYear + 7,
              }},[_vm._v(" "+_vm._s(item.type != 'subCategoryTotalResult' ? _vm.numFormat(value) : _vm.numFormat(value, '0,0'))+" ")])})],2)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }