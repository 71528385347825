<template>
  <div class="table-responsive mb-2">
    <table class="table table-sm mb-0 table-dashboard fs--0" style="font-family: 'Proxima Nova'">
      <thead class="bg-400 text-800">
        <tr>                   
          <th 
            class="pl-3 text-center" 
            style="min-width: 300px; vertical-align: middle;" 
            rowspan="2">
            Наименование статьи
          </th>
          <th v-for="y in 3" :key="y"
            class="text-center"
            style="min-width: 80px; border-left: 1px solid #06132550;"
            scope="colgroup">
            {{activeBPlan.startYear + (y - 1)}} <small>год</small> 
          </th>
        </tr>
        
      </thead>
      <tbody id="oneYearSales" style="font-size: 0.7rem;">
        <tr 
          v-for="item in PLData.data"
          :key="item.key"
          class="hover-shadow">
          <td class="p-0"
            :class="{
              'pl-3 fs--1': item.type == 'categoryItem',
              'pl-3 item-total': item.type == 'categoryItemTotal',
              'sub-item pl-4': item.type == 'categorySubItem',
              'fs-total': item.type == 'categoryTotal',
              'fs-total result': item.type == 'categoryTotalResult',
              'sub-category-total-result text-right fs--2 pr-2': item.type == 'subCategoryTotalResult',
              'border-top-400': item.type == 'categoryTotal' || item.type == 'categoryTotalResult',
              'segment-total border-top-400 title': item.type == 'segmentTotal'
            }">
            {{item.title}}                
          </td>
          <td 
            v-for="(value, index) in item.values"
            :key="index.key"
            class="text-right py-0 pr-1"
            style="vertical-align: bottom; min-width: 70px;"
            :class="{
              'bg-100': (value == null) && (item.type == 'categoryItem'),
              'border-left-300': index != 0,
              'border-left-400': index == 0,
              'item-total': item.type == 'categoryItemTotal',
              'sub-item': item.type == 'categorySubItem',
              'border-top-400': item.type == 'categoryTotal',
              'item-total result': item.type == 'categoryTotalResult',
              'border-left-400' : index == 0 ||
                                  index == activeYear + 4 ||
                                  index == activeYear + 5 || 
                                  index == activeYear + 6 || 
                                  index == activeYear + 7,
              'categoryTotal': item.type == 'categoryTotal' || item.type == 'categoryTotalResult',
              'segment-total border-top-400 negative-value': (item.type == 'segmentTotal') && (value < 0),
              'sub-category-total-result': item.type == 'subCategoryTotalResult',
              'segment-total border-top-400': item.type == 'segmentTotal',
              'negative-value': item.type == 'subCategoryTotalResult' && value < 0
            }">
            {{item.type != 'subCategoryTotalResult' ? numFormat(value) : numFormat(value, '0,0.0')}}
            <small v-if="item.type == 'subCategoryTotalResult' && value != 0">%</small>  
          </td>
        </tr>
      </tbody>
      <tfoot class="bg-300 text-800 font-weight-medium"
        style="border-top: 2px double #757e8c;">
        <tr>
          <td class="align-middle font-weight-semi-bold p-0 pl-2"
              colspan="8">
            Остаток денежных средств
          </td>
        </tr>
        <tr v-for="(item, rowIndex) in PLData.cashBalance" :key="item.key">
          <td class="p-0 align-middle"
            :class="{
              'font-weight-semi-bold p-1': item.type == 'categoryTotalResult',
              'font-weight-semi-bold text-right pr-2 fs--2': item.type == 'subCategoryTotalResult',
              'font-weight-semi-bold sub-category-total-result': item.type == 'subCategoryTotalResult',
            }">
            {{item.title}}
          </td>
          <td 
            v-for="(value, index) in item.values"
            :key="index.key"
            class="text-right p-0 pr-1 fs--2 align-bottom"
            :class="{
              'font-weight-semi-bold p-1': item.type == 'categoryTotalResult',
              'negative-value balance': value < 0,
              'fs--2': rowIndex == 1,
              'font-weight-semi-bold fs--1': rowIndex != 1,
              'border-left-300': index != 0,
              ' sub-category-total-result': item.type == 'subCategoryTotalResult',
              'border-left-400' : index == 0 || 
                                  index == activeYear + 4 || 
                                  index == activeYear + 5 || 
                                  index == activeYear + 6 || 
                                  index == activeYear + 7,
            }">
            {{item.type != 'subCategoryTotalResult' ? numFormat(value) : numFormat(value, '0,0')}}
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import numeral from 'numeral'

export default {
  data: () => ({
    activeYear: 0
  }),

  mounted() {
    this.$store.dispatch('calculateTableCF', { activeYear: -1 })
  },

  computed: {
    activeBPlan() {
      return this.$store.getters.getActiveBPlan;
    },

    PLData() {
      return this.$store.getters.getTableCF
    },
  },

  methods: {
    numFormat(value, dec = '0,0') {
      if (value == 0 || value == null) {
        return '-  '
      }
      return numeral(value).format(dec)
    },
  }

}
</script>

<style lang="scss" scoped>

.border-left-400 {
  border-left: 1px solid #4f5c70 !important;
}

.segment-total {
  height: 1.35rem;
  background: #5e6e82;
  color: #b6c1d2;
  font-weight: 600;
  font-size: 0.8rem;
  vertical-align: middle;
  text-transform: uppercase;
  &.title {
    padding-left: 0.4rem !important;
    vertical-align: middle;
    height: 1.5rem;
  }
}

.fs-total {
  height: 1.5rem;
  padding-left: 5px !important;
  background: #34405055;
  font-weight: 600;
  font-size: 0.8rem;
  vertical-align: middle;
  &.result {
    background: #344050;
  }
}

.categoryTotal {
  background: #34405055;
  font-weight: 600;
  vertical-align: bottom;
  font-size: 0.75rem;
}

.sub-category-total-result {
  background: #344050;
  border-top: 1px solid #2a3441;
}

.item-total {
  background: #34405055;
  &.result {
    background: #344050;
  }
}

.sub-item {
  background: #34405050
}

.negative-value {
  color: #89140e;
  &.balance {
    color: #3b0b09;
  }
}


</style>